var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _vm.company.id
                            ? _c("h1", [
                                _vm._v("Editing " + _vm._s(_vm.company.name)),
                              ])
                            : _c("h1", [_vm._v("Creating new company")]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Identification"),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "name",
                                                    },
                                                    model: {
                                                      value: _vm.company.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.name",
                                                    },
                                                  }),
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "tax_registration_number",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company
                                                          .tax_registration_number,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "tax_registration_number",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.tax_registration_number",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm.company.image &&
                                                  !_vm.company.newImage
                                                    ? _c("v-img", {
                                                        staticStyle: {
                                                          "max-width": "150px",
                                                          height: "auto",
                                                        },
                                                        attrs: {
                                                          src: _vm.company
                                                            .image,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "image-uploader",
                                                    {
                                                      attrs: {
                                                        maxWidth: 450,
                                                        quality: 0.9,
                                                        autoRotate: true,
                                                        preview: true,
                                                        capture: false,
                                                        accept: "image/*",
                                                      },
                                                      on: {
                                                        input: _vm.setImage,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "d-block text-center",
                                                          attrs: {
                                                            slot: "upload-label",
                                                            for: "fileInput",
                                                          },
                                                          slot: "upload-label",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa fa-camera"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "upload-caption",
                                                            },
                                                            [_vm._v("Logo")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "national_id",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company.national_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "national_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.national_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "international_id",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company
                                                          .international_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "international_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.international_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("m-field", {
                                            attrs: {
                                              m: "company",
                                              a: "address",
                                            },
                                            model: {
                                              value: _vm.company.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "company.address",
                                            },
                                          }),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "city",
                                                    },
                                                    model: {
                                                      value: _vm.company.city,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "city",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.city",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "zipcode",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company.zipcode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "zipcode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.zipcode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("m-field", {
                                                    attrs: {
                                                      m: "company",
                                                      a: "state",
                                                    },
                                                    model: {
                                                      value: _vm.company.state,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "state",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.state",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      name: "country_id",
                                                      rules:
                                                        _vm.rules.company
                                                          .country_id,
                                                      component:
                                                        "v-autocomplete",
                                                      items: _vm.countries,
                                                      "item-text": function (
                                                        item
                                                      ) {
                                                        return _vm.translate(
                                                          item.name
                                                        )
                                                      },
                                                      "item-value": "id",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company.country_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "country_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.country_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v(
                                          " Typologie, secteur & cotation "
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("m-field", {
                                            attrs: {
                                              m: "company",
                                              a: "legal_typology_id",
                                              component: "v-select",
                                              items: _vm.legalTypologies,
                                              "item-text": function (item) {
                                                return _vm.translate(item.name)
                                              },
                                              "item-value": "id",
                                            },
                                            model: {
                                              value:
                                                _vm.company.legal_typology_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "legal_typology_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "company.legal_typology_id",
                                            },
                                          }),
                                          _c("h2", [_vm._v("Devise")]),
                                          _c("m-field", {
                                            attrs: {
                                              m: "company",
                                              a: "profil_fmp",
                                              component: "v-select",
                                              items: [
                                                "EUR",
                                                "AUD",
                                                "TRY",
                                                "SEK",
                                                "INR",
                                                "JPY",
                                                "KRW",
                                                "USD",
                                                "NOK",
                                                "CNY",
                                                "CAD",
                                                "THB",
                                                "GBp",
                                                "HKD",
                                                "TWD",
                                                "ZAc",
                                                "IDR",
                                                "BRL",
                                                "PLN",
                                                "SGD",
                                                "ILA",
                                                "QAR",
                                                "SAR",
                                                "DKK",
                                                "CLP",
                                                "MXN",
                                                "CHF",
                                                "NZD",
                                                "MYR",
                                                "ISK",
                                                "ARS",
                                                "CZK",
                                                "GBP",
                                                "HUF",
                                                "AED",
                                                "KWF",
                                                "TND",
                                              ],
                                            },
                                            model: {
                                              value:
                                                _vm.company.profil_fmp.currency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company.profil_fmp,
                                                  "currency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "company.profil_fmp.currency",
                                            },
                                          }),
                                          _c("h2", [_vm._v("Activities")]),
                                          _vm._l(
                                            _vm.company.companyActivities,
                                            function (ca, i) {
                                              return _c(
                                                "div",
                                                { key: i },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Activity " +
                                                        _vm._s(i + 1)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        "x-small": "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.company.companyActivities.splice(
                                                            i,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                          },
                                                        },
                                                        [_vm._v("fa fa-trash")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { attrs: { md: "8" } },
                                                        [
                                                          _c("field", {
                                                            attrs: {
                                                              name:
                                                                "companyActivities[" +
                                                                i +
                                                                "].activity_id",
                                                              label: _vm.$t(
                                                                "models.companyActivity.activity"
                                                              ),
                                                              rules:
                                                                _vm.rules
                                                                  .company_activity
                                                                  .activity_id,
                                                              component:
                                                                "ActivitySelector",
                                                            },
                                                            model: {
                                                              value:
                                                                ca.activity_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    ca,
                                                                    "activity_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ca.activity_id",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        { attrs: { md: "4" } },
                                                        [
                                                          _c("field", {
                                                            attrs: {
                                                              name:
                                                                "companyActivities[" +
                                                                i +
                                                                "].percent",
                                                              label: _vm.$t(
                                                                "models.company_activity.percent"
                                                              ),
                                                              type: "number",
                                                              min: "0",
                                                              max: "100",
                                                              rules:
                                                                _vm.rules
                                                                  .company_activity
                                                                  .percent,
                                                            },
                                                            model: {
                                                              value: ca.percent,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    ca,
                                                                    "percent",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ca.percent",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { "x-small": "" },
                                              on: { click: _vm.addActivity },
                                            },
                                            [_vm._v("Add an activity")]
                                          ),
                                          _c("h2", [
                                            _vm._v("Cotation en bourse"),
                                          ]),
                                          _c(
                                            "field",
                                            {
                                              attrs: {
                                                name: "traded",
                                                rules: _vm.rules.company.traded,
                                                component: "v-radio-group",
                                                row: "",
                                              },
                                              model: {
                                                value: _vm.company.traded,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company,
                                                    "traded",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "company.traded",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  value: 1,
                                                  label: "Yes",
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  value: 0,
                                                  label: "No",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.company.traded
                                            ? [
                                                _c("m-field", {
                                                  attrs: {
                                                    m: "company",
                                                    a: "stock_market_id",
                                                    component: "v-select",
                                                    items: _vm.stockMarkets,
                                                    "item-text": "name.name_fr",
                                                    "item-value": "id",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company
                                                        .stock_market_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company,
                                                        "stock_market_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company.stock_market_id",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v(" Contacts "),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("m-field", {
                                            attrs: { m: "company", a: "phone" },
                                            model: {
                                              value: _vm.company.phone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "phone",
                                                  $$v
                                                )
                                              },
                                              expression: "company.phone",
                                            },
                                          }),
                                          _c("m-field", {
                                            attrs: { m: "company", a: "email" },
                                            model: {
                                              value: _vm.company.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "company.email",
                                            },
                                          }),
                                          _c("m-field", {
                                            attrs: { m: "company", a: "url" },
                                            model: {
                                              value: _vm.company.url,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company,
                                                  "url",
                                                  $$v
                                                )
                                              },
                                              expression: "company.url",
                                            },
                                          }),
                                          _c("h2", [_vm._v("Extra Contacts")]),
                                          _vm._l(
                                            _vm.company.contacts,
                                            function (contact, i) {
                                              return _c(
                                                "div",
                                                { key: "con-" + i },
                                                [
                                                  i > 0
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            "x-small": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeContact(
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "fa fa-trash"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("b", [
                                                    _vm._v(
                                                      "Contact " + _vm._s(i + 1)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "field",
                                                    {
                                                      attrs: {
                                                        rules:
                                                          _vm.rules
                                                            .companyContact
                                                            .gender,
                                                        component:
                                                          "v-radio-group",
                                                        name:
                                                          "contacts[" +
                                                          i +
                                                          "].gender",
                                                        row: "",
                                                      },
                                                      model: {
                                                        value: contact.gender,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            contact,
                                                            "gender",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "contact.gender",
                                                      },
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: "m",
                                                          label: "Mr",
                                                        },
                                                      }),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          value: "f",
                                                          label: "Mme",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "models.company.name"
                                                      ),
                                                      name:
                                                        "contacts[" +
                                                        i +
                                                        "].name",
                                                      rules:
                                                        _vm.rules.companyContact
                                                          .name,
                                                    },
                                                    model: {
                                                      value: contact.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.name",
                                                    },
                                                  }),
                                                  _c("field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "models.company.contactTitle"
                                                      ),
                                                      name:
                                                        "contacts[" +
                                                        i +
                                                        "].title",
                                                      rules:
                                                        _vm.rules.companyContact
                                                          .title,
                                                    },
                                                    model: {
                                                      value: contact.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.title",
                                                    },
                                                  }),
                                                  _c("field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "models.company.phone"
                                                      ),
                                                      name:
                                                        "contacts[" +
                                                        i +
                                                        "].phone",
                                                      rules:
                                                        _vm.rules.companyContact
                                                          .phone,
                                                    },
                                                    model: {
                                                      value: contact.phone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.phone",
                                                    },
                                                  }),
                                                  _c("field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "models.company.email"
                                                      ),
                                                      name:
                                                        "contacts[" +
                                                        i +
                                                        "].email",
                                                      rules:
                                                        _vm.rules.companyContact
                                                          .email,
                                                    },
                                                    model: {
                                                      value: contact.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          contact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "contact.email",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { "x-small": "" },
                                              on: { click: _vm.addContact },
                                            },
                                            [_vm._v("Add a contact")]
                                          ),
                                          _c("h2", [_vm._v("Admin")]),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _vm._v(
                                                    "Published on website "
                                                  ),
                                                  _c("v-switch", {
                                                    attrs: {
                                                      name: "web",
                                                      rules:
                                                        _vm.rules.company.web,
                                                    },
                                                    model: {
                                                      value: _vm.company.web,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "web",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "company.web",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _vm._v("Enabled in admin "),
                                                  _c("v-switch", {
                                                    attrs: {
                                                      rules:
                                                        _vm.rules.company
                                                          .enabled,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company.enabled,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company,
                                                          "enabled",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company.enabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            { attrs: { type: "submit", color: "primary" } },
                            [_vm._v(_vm._s(_vm.$t("actions.save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1257491110
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }